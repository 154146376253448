.collections-page {
  & .collections-main {
    margin-top: 64px;
    margin-bottom: 64px;

    & .collections-body {
      max-width: 1000px;
      padding: 0 20px;

      & .collection-heading {
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 38px;
      }

      .category-items-section {
        & .category-item-header {
          font-size: 18px;
          font-weight: 500;
          margin: 0;
        }

        & .category-item-body {
          padding: 10px 0 24px;

          & > section {
            display: grid;
            grid-auto-rows: auto;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            justify-content: center;
            justify-items: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 786px) {
    & {
      & .collections-main {
        margin-top: 48px;
        margin-bottom: 48px;

        & .collections-body {
          & .collection-heading {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 32px;
          }

          & .category-items-section {
            & .category-item-header {
              font-size: 16px;
            }

            & .category-item-body {
              padding: 20px 0 24px;

              & > section {
                grid-template-columns: 50% 50%;
              }
            }
          }
        }
      }
    }
  }
}
